import {
  EinsteinEngineItem,
  type EinsteinLineupItem,
} from '@/components/shared/einstein/lineup/card';
import { ImageProps } from '@/components/shared/media/image/Image.props';
import {
  HP_VALUE,
  TORQUE_VALUE,
} from '@/contents/lcv/product/maxforce/shared/engine';
import { IMy24Model } from '@/types/shared';

interface IData {
  price: number;
  label: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

export const V_CROSS_DATA: IData = {
  price: 937000,
  label: 'วี-ครอส 4x4',
  url: '/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc85301a194024d99/673469ba3bea8fbb3a123798/v-cross.png',
    alt: 'ISUZU V-Cross 4x4',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb3c0cc339be4b01a/v-cross-brochure-2025.pdf',
  //NOTE: permanent link
};

export const X_SERIES_DATA: IData = {
  price: 763000,
  label: 'เอ็กซ์-ซีรี่ส์',
  url: '/isuzu-x-series',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltadbc7b00934f5ce6/65ac707ea933e51c1902763a/x-series.png',
    alt: 'x-series',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0ae8a893222ff3a9/x-series-brochure-2024.pdf',
  //NOTE: permanent link
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 749000,
  label: 'ปิกอัพ 4 ประตู',
  url: '/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5b3dcd6f5876f074/673469ba4f4fa3c4e1b7b45c/4-door.png',
    alt: 'pickup 4-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt3b41354a46ee0207/4-doors-brochure-2025.pdf',
  //NOTE: permanent link
};

export const PICKUP_2DOOR_DATA: IData = {
  price: 668000,
  label: 'ปิกอัพ 2 ประตู',
  url: '/isuzu-pick-up-2-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt4d77099b3da9d608/673469baa6bfe72a8e9c1818/2-door.png',
    alt: 'pickup 2-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6433a174955313eb/2-doors-brochure-2025.pdf',
  //NOTE: permanent link
};

export const SPARK_DATA: IData = {
  price: 595000,
  label: 'สปาร์ค',
  url: '/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltea6d380144222014/673469ba09c2c07eef61f0b8/spark.png',
    alt: 'spark',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt390ae94120874d15/spark-brochure-2025.pdf',
  //NOTE: permanent link
};

export const MUX_ULTIMATE_DATA: IData = {
  price: 1194000,
  label: 'มิว-เอ็กซ์',
  url: '/isuzu-mu-x?model=ultimate',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6cd60581a482ffa2/66694098371bca4188b8cf48/ultimate.png',
    alt: 'MU-X Ultimate',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbafd73a150db898b/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
};

export const MUX_RS_DATA: IData = {
  price: 1624000,
  label: 'มิว-เอ็กซ์ อาร์เอส',
  url: '/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb3c905d047206833/66694073b00d39b961dfe211/rs.png',
    alt: 'MU-X RS',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbafd73a150db898b/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
  // FOR SHOW A BADGE
  new: true,
};

export const X_SERIES_MENU: IMenu = {
  label: X_SERIES_DATA.label,
  description: `เริ่มต้น THB ${X_SERIES_DATA.price.toLocaleString()}`,
  url: X_SERIES_DATA.url,
  image: X_SERIES_DATA.image,
  brochure: X_SERIES_DATA.brochure,
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `เริ่มต้น THB ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `เริ่มต้น THB ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const PICKUP_2DOOR_MENU: IMenu = {
  label: PICKUP_2DOOR_DATA.label,
  description: `เริ่มต้น THB ${PICKUP_2DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_2DOOR_DATA.url,
  image: PICKUP_2DOOR_DATA.image,
  brochure: PICKUP_2DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `เริ่มต้น THB ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_ULTIMATE_DATA_MENU: IMenu = {
  label: MUX_ULTIMATE_DATA.label,
  description: `เริ่มต้น THB ${MUX_ULTIMATE_DATA.price.toLocaleString()}`,
  url: MUX_ULTIMATE_DATA.url,
  image: MUX_ULTIMATE_DATA.image,
  brochure: MUX_ULTIMATE_DATA.brochure,
};

export const MUX_RS_DATA_MENU: IMenu = {
  label: MUX_RS_DATA.label,
  description: `เริ่มต้น THB ${MUX_RS_DATA.price.toLocaleString()}`,
  url: MUX_RS_DATA.url,
  image: MUX_RS_DATA.image,
  brochure: MUX_RS_DATA.brochure,
  // FOR SHOW A BADGE
  new: MUX_RS_DATA.new,
};

export const getModelLineupId = (model: IMy24Model) => {
  const MODEL_LINE_UP_ID = {
    'mu-x': 5,
    '4-door': 1,
    '2-door': 2,
    spark: 3,
    'v-cross': 4,
    'x-series': 6,
  };
  return MODEL_LINE_UP_ID[model] || 1;
};

export const getModelLineupOrderByModel = (
  model: IMy24Model,
  items: EinsteinLineupItem[]
) => {
  const id = getModelLineupId(model);
  if (id === items.length) {
    return items.slice(0, id - 1);
  }
  return [...items.slice(id), ...items.slice(0, id - 1)];
};

const PRODUCT_ENGINE_DATA: { [key: string]: EinsteinEngineItem[] } = {
  'MU-X': [
    {
      key: '2.2',
      title: 'เครื่องยนต์ 2.2',
      power: HP_VALUE['2.2'],
      torque: TORQUE_VALUE['2.2'],
      startPrice: 1194000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95d5d1008250eead/66692ca146a0375d52a26414/mu-x-front-rs-eiger-grey.png',
        alt: `${MUX_ULTIMATE_DATA.label} 2.2`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=mu-x-4x2-2-2-rs&model=mu-x-4x2-2-2-ultimate&model=mu-x-4x2-2-2-elegant',
    },
    {
      key: '3.0',
      title: 'เครื่องยนต์ 3.0',
      power: HP_VALUE['3.0'],
      torque: TORQUE_VALUE['3.0'],
      startPrice: 1464000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt92ec37d231016caa/66692c99242470bafeac5da7/mu-x-front-rs-dolomite-white-pearl.png',
        alt: `${MUX_ULTIMATE_DATA.label} 3.0`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=mu-x-4x4-3-0-rs&model=mu-x-4x2-3-0-rs&model=mu-x-4x2-3-0-ultimate',
    },
  ],
  'V-CROSS': [
    {
      key: '3.0',
      title: 'เครื่องยนต์ 3.0',
      power: HP_VALUE['3.0'],
      torque: TORQUE_VALUE['3.0'],
      startPrice: 937000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd533380cb53fdb65/6732dd2c4b891d39d171bd51/v-cross-4d-front-m-elbrus-gray-opaque.png',
        alt: V_CROSS_DATA.label,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=v-cross-3-0-ddi-m-4-door-at&model=v-cross-3-0-ddi-zp-4-door-at&model=v-cross-3-0-ddi-zp-4-door-mt',
    },
  ],
  '4-DOOR': [
    {
      key: '2.2',
      title: 'เครื่องยนต์ 2.2',
      power: HP_VALUE['2.2'],
      torque: TORQUE_VALUE['2.2'],
      startPrice: 749000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta5047bd474b5d3ed/6732dfb7a26dfe7cc01dbffb/hi-4d-2-2-front-m-elbrus-gray-opaque.png',
        alt: `${PICKUP_4DOOR_DATA.label} 2.2`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=4-door-hi-lander-4-door-2-2-ddi-m-at&model=4-door-hi-lander-4-door-2-2-ddi-zp-at&model=4-door-hi-lander-4-door-2-2-ddi-z-at',
    },
    {
      key: '3.0',
      title: 'เครื่องยนต์ 3.0',
      power: HP_VALUE['3.0'],
      torque: TORQUE_VALUE['3.0'],
      startPrice: 1056000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfb9f9dd59b911f10/6732dd2ca3eb8e594a3e1ea9/hi-4d-3-0-front-m-elbrus-gray-opaque.png',
        alt: `${PICKUP_4DOOR_DATA.label} 3.0`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=4-door-hi-lander-4-door-3-0-ddi-m-at&model=4-door-hi-lander-4-door-3-0-ddi-zp-mt&model=4-door-hi-lander-4-door-2-2-ddi-m-at',
    },
  ],
  '2-DOOR': [
    {
      key: '2.2',
      title: 'เครื่องยนต์ 2.2',
      power: HP_VALUE['2.2'],
      torque: TORQUE_VALUE['2.2'],
      startPrice: 668000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6ef830d48c0c6f1a/6732dd2c9017dce9d3b36edc/spacecab-front-l-elbrus-gray-opaque.png',
        alt: `${PICKUP_2DOOR_DATA.label} 2.2`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=2-door-spacecab-2-2-ddi-l-at&model=2-door-spacecab-2-2-ddi-s-at&model=2-door-hi-lander-2-door-2-2-ddi-zp-at',
    },
    {
      key: '3.0',
      title: 'เครื่องยนต์ 3.0',
      power: HP_VALUE['3.0'],
      torque: TORQUE_VALUE['3.0'],
      startPrice: 693000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbd36462ab690e781/6735c114a3ae0eeb2d197a31/spacecab-3-0-front-s-elbrus-gray-opaque.png',
        alt: `${PICKUP_2DOOR_DATA.label} 3.0`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=2-door-spacecab-3-0-ddi-s-mt&model=2-door-spacecab-2-2-ddi-l-at&model=2-door-hi-lander-2-door-2-2-ddi-zp-at',
    },
  ],
  SPARK: [
    {
      key: '2.2',
      title: 'เครื่องยนต์ 2.2',
      power: HP_VALUE['2.2'],
      torque: TORQUE_VALUE['2.2'],
      startPrice: 595000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt711f8d971ebe3741/6732df25e71e4923f2f0fad4/spark-4x2-front-s-elbrus-gray-opaque.png',
        alt: `${SPARK_DATA.label} 2.2`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=spark-spark-2-2-ddi-s-at&model=spark-spark-2-2-ddi-s-mt&model=spark-spark-2-2-ddi-b-mt',
    },
    {
      key: '3.0',
      title: 'เครื่องยนต์ 3.0',
      power: HP_VALUE['3.0'],
      torque: TORQUE_VALUE['3.0'],
      startPrice: 648000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6fffa71194bab94c/6732dd2cf41370a3167c7dba/spark-4x4-front-s-elbrus-gray-opaque.png',
        alt: `${SPARK_DATA.label} 3.0`,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=spark-spark-4x4-3-0-ddi-s-at&model=spark-spark-4x4-3-0-ddi-s-mt&model=spark-spark-3-0-ddi-s-mt',
    },
  ],
  'X-SERIES': [
    {
      key: '1.9',
      title: 'เครื่องยนต์ 1.9',
      power: HP_VALUE['1.9'],
      torque: TORQUE_VALUE['1.9'],
      startPrice: 763000,
      modelImage: {
        src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta320386b316dfe35/65a7f250c992b705a5b89fc7/x-series-front-speed-4-door-mt-bavarian-black-mica.png',
        alt: X_SERIES_DATA.label,
        width: 600,
        height: 338,
      },
      compareUrl:
        '/compare?model=x-series-hi-lander-4-door-at&model=x-series-hi-lander-4-door-mt&model=x-series-speed-4-door-mt',
    },
  ],
};

export const HOME_LINEUP_DATA: EinsteinLineupItem[] = [
  {
    preTitle: '4-DOOR',
    title: '4-DOOR',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt290c9039660cc515/6732f52b4f4fa32531b7ad1b/lineup-mobile-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 360,
      height: 450,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2ed8166f2ff6d42d/6732d754a5c7fc3abe0c949e/lineup-desktop-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1022,
      height: 381,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-pick-up-4-door',
        color: 'button-white',
      },
      {
        label: 'คำนวณค่างวด',
        url: '/calculator?model=4-door',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['4-DOOR'],
  },
  {
    preTitle: '2-DOOR',
    title: '2-DOOR',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8295c82ad1a9464d/6735dd2377c00d43b3a17e35/lineup-mobile-2-door.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 360,
      height: 450,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8295c82ad1a9464d/6732d75609c2c0ffba61e903/lineup-desktop-2-door.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1022,
      height: 381,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-pick-up-2-door',
        color: 'button-white',
      },
      {
        label: 'คำนวณค่างวด',
        url: '/calculator?model=2-door',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['2-DOOR'],
  },
  {
    preTitle: 'SPARK',
    title: 'SPARK',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1f1b430bd322d02d/6732f55315798d50ad01e0ef/lineup-mobile-spark.jpg',
      alt: SPARK_DATA.label,
      width: 360,
      height: 450,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt193da9fec0ad1235/6732d754d01b2c271e8eb587/lineup-desktop-spark.jpg',
      alt: SPARK_DATA.label,
      width: 1022,
      height: 381,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-spark',
        color: 'button-white',
      },
      {
        label: 'คำนวณค่างวด',
        url: '/calculator?model=spark',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['SPARK'],
  },
  {
    preTitle: 'V-CROSS',
    title: 'V-CROSS',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt99e2a8778942dedc/6732f50f020af3fcbfbe66a3/lineup-mobile-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 360,
      height: 450,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt08eadecc46124c5b/6732d754069a8624db818829/lineup-desktop-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 1022,
      height: 381,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-v-cross',
        color: 'button-white',
      },
      {
        label: 'คำนวณค่างวด',
        url: '/calculator?model=v-cross',
        color: 'button-gray',
      },
    ],
    theme: 'dark',
    engines: PRODUCT_ENGINE_DATA['V-CROSS'],
  },
  {
    preTitle: 'MU-X',
    title: 'MU-X',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt80582c42864c25db/673c4bd949f7ad2db68c6a91/lineup-mobile-mu-x.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 360,
      height: 450,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltad268dd2523a7774/673c4bd985819447b70e0b56/lineup-desktop-mu-x.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 1022,
      height: 381,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-mu-x',
        color: 'button-white',
      },
      {
        label: 'คำนวณค่างวด',
        url: '/calculator?model=mu-x',
        color: 'button-gray',
      },
    ],
    theme: 'dark',
    engines: PRODUCT_ENGINE_DATA['MU-X'],
  },
  {
    preTitle: 'X-SERIES',
    title: 'X-SERIES',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7d0ca626ac6776fc/6737308c44c6b9ccee348eb1/lineup-mobile-x-series.jpg',
      alt: X_SERIES_DATA.label,
      width: 360,
      height: 450,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt861118b4594ae3e5/6735dcb977c00d6788a17e2e/lineup-desktop-x-series.jpg',
      alt: X_SERIES_DATA.label,
      width: 1022,
      height: 381,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-x-series',
        color: 'button-white',
      },
      {
        label: 'คำนวณค่างวด',
        url: '/calculator?model=x-series',
        color: 'button-gray',
      },
    ],
    theme: 'dark',
    engines: PRODUCT_ENGINE_DATA['X-SERIES'],
  },
];

export const PRODUCT_LINEUP_DATA: EinsteinLineupItem[] = [
  {
    title: '4-DOOR',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt711d4d926a3b4441/6732f296c741493e36c30be9/product-lineup-mobile-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 312,
      height: 416,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt711d4d926a3b4441/6732f296c741493e36c30be9/product-lineup-mobile-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 352,
      height: 470,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-pick-up-4-door',
        color: 'button-white',
      },
      {
        label: 'เปรียบเทียบ',
        url: '/compare?model=v-cross-3-0-ddi-m-4-door-at&model=4-door-hi-lander-4-door-3-0-ddi-m-at&model=2-door-hi-lander-2-door-1-9-ddi-zp-at',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['4-DOOR'],
  },
  {
    title: '2-DOOR',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0e62de94185672dd/6732f2968a44186f172da477/product-lineup-mobile-2-door.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 312,
      height: 416,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0e62de94185672dd/6732f2968a44186f172da477/product-lineup-mobile-2-door.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 352,
      height: 470,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-pick-up-2-door',
        color: 'button-white',
      },
      {
        label: 'เปรียบเทียบ',
        url: '/compare?model=v-cross-3-0-ddi-m-4-door-at&model=4-door-hi-lander-4-door-3-0-ddi-m-at&model=2-door-hi-lander-2-door-1-9-ddi-zp-at',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['2-DOOR'],
  },
  {
    title: 'SPARK',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7a780043cf9de319/6732f29677c00d47eaa13f49/product-lineup-mobile-spark.jpg',
      alt: SPARK_DATA.label,
      width: 312,
      height: 416,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7a780043cf9de319/6732f29677c00d47eaa13f49/product-lineup-mobile-spark.jpg',
      alt: SPARK_DATA.label,
      width: 352,
      height: 470,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-spark',
        color: 'button-white',
      },
      {
        label: 'เปรียบเทียบ',
        url: '/compare?model=spark-spark-4x4-3-0-ddi-s-mt&model=v-cross-3-0-ddi-m-4-door-at&model=4-door-hi-lander-4-door-3-0-ddi-m-at',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['SPARK'],
  },
  {
    title: 'V-CROSS',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt52c69daf344baedf/6732f29697ce06fe89478d62/product-lineup-mobile-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 312,
      height: 416,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt52c69daf344baedf/6732f29697ce06fe89478d62/product-lineup-mobile-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 352,
      height: 470,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-v-cross',
        color: 'button-white',
      },
      {
        label: 'เปรียบเทียบ',
        url: '/compare?model=v-cross-3-0-ddi-m-4-door-at&model=4-door-hi-lander-4-door-3-0-ddi-m-at&model=2-door-hi-lander-2-door-1-9-ddi-zp-at',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['V-CROSS'],
  },
  {
    title: 'MU-X',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta9b6ae9284ee5ed7/673c4c658a260ce6eea5ec08/product-lineup-mobile-mu-x.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 312,
      height: 416,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta9b6ae9284ee5ed7/673c4c658a260ce6eea5ec08/product-lineup-mobile-mu-x.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 352,
      height: 470,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-mu-x',
        color: 'button-white',
      },
      {
        label: 'เปรียบเทียบ',
        url: '/compare?model=mu-x-4x4-3-0-rs&model=mu-x-4x2-3-0-ultimate&model=mu-x-4x2-3-0-elegant',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['MU-X'],
  },
  {
    title: 'X-SERIES',
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt354eacd75375051e/6735dd93e8347d5dd60a4fe4/product-lineup-mobile-x-series.jpg',
      alt: X_SERIES_DATA.label,
      width: 312,
      height: 416,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt354eacd75375051e/6735dd93e8347d5dd60a4fe4/product-lineup-mobile-x-series.jpg',
      alt: X_SERIES_DATA.label,
      width: 352,
      height: 470,
    },
    buttons: [
      {
        label: 'ดูรายละเอียด',
        url: '/isuzu-x-series',
        color: 'button-white',
      },
      {
        label: 'เปรียบเทียบ',
        url: '/compare?model=x-series-hi-lander-4-door-at&model=v-cross-3-0-ddi-m-4-door-at&model=mu-x-4x4-3-0-ultimate-phantom-collection',
        color: 'button-gray',
      },
    ],
    engines: PRODUCT_ENGINE_DATA['X-SERIES'],
  },
];
