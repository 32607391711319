import type { ImageProps } from '@/components/shared/media/image/Image.props';
import { IMy24LineupItem, IMy24Model } from '@/types/shared';

interface IData {
  price: number;
  label: string;
  url: string;
  image: ImageProps;
  brochure: string;
  euro4BrochureLink?: string;
  new?: boolean;
}

interface IMenu {
  label: string;
  description: string;
  url: string;
  image: ImageProps;
  brochure: string;
  new?: boolean;
}

export const X_SERIES_DATA: IData = {
  price: 763000,
  label: 'เอ็กซ์-ซีรี่ส์',
  url: '/isuzu-x-series',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltadbc7b00934f5ce6/65ac707ea933e51c1902763a/x-series.png',
    alt: 'x-series',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0ae8a893222ff3a9/x-series-brochure-2024.pdf',
  // NOTE: permanent link
  euro4BrochureLink:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt26720df926db295a/65e959e6c4428d7e47a1e434/x-series-brochure-2024.pdf', // NOTE: permanent link
};

export const V_CROSS_DATA: IData = {
  price: 937000,
  label: 'วี-ครอส 4x4',
  url: '/bluepower/isuzu-v-cross',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt12769a814d704a7a/651f81807bedefbe0194d8c0/v-cross.png',
    alt: 'v-cross 4x4',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6816b2c0528120f2/v-cross-brochure-2024.pdf',
  //NOTE: permanent link
  euro4BrochureLink:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt0439407d7e38c393/v-cross-brochure-2024.pdf',
  //NOTE: permanent link
};

export const PICKUP_4DOOR_DATA: IData = {
  price: 746000,
  label: 'ปิกอัพ 4 ประตู',
  url: '/bluepower/isuzu-pick-up-4-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc418a09e066ba4d4/651f819a1d2dc3807bfba1c8/4-Door.png',
    alt: 'pickup 4-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf5f12576135cc3bf/4-door-brochure-2024.pdf', //NOTE: permanent link
  euro4BrochureLink:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd1e0ee3ba84eca85/4-doors-brochure-2024.pdf', // NOTE: permanent link
};

export const PICKUP_2DOOR_DATA: IData = {
  price: 665000,
  label: 'ปิกอัพ 2 ประตู',
  url: '/bluepower/isuzu-pick-up-2-door',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7e119782e8d98b24/651f81a6964d9b7a27c328df/2-Door.png',
    alt: 'ISUZU Pickup 2-door',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt065fad7ba124fda4/2-door-brochure-2024.pdf', // NOTE: permanent link
  euro4BrochureLink:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt79dedccdf8ca0c2d/2-doors-brochure-2024.pdf', // NOTE: permanent link
};

export const SPARK_DATA: IData = {
  price: 592000,
  label: 'สปาร์ค',
  url: '/bluepower/isuzu-spark',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbeb996cb3211b381/651f81b4705ef3a03548b554/spark.png',
    alt: 'spark',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9e4c25361495fbaa/spark-brochure-2024.pdf', //NOTE: permanent link
  euro4BrochureLink:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt708a91436668f9c4/spark-brochure-2024.pdf', //NOTE: permanent link
};

export const MUX_ULTIMATE_DATA: IData = {
  price: 1184000,
  label: 'มิว-เอ็กซ์',
  url: '/bluepower/isuzu-mu-x?model=ultimate',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6cd60581a482ffa2/66694098371bca4188b8cf48/ultimate.png',
    alt: 'MU-X Ultimate',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb748a93277ad1be9/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
};

export const MUX_RS_DATA: IData = {
  price: 1659000,
  label: 'มิว-เอ็กซ์ อาร์เอส',
  url: '/bluepower/isuzu-mu-x',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb3c905d047206833/66694073b00d39b961dfe211/rs.png',
    alt: 'MU-X RS',
    width: 1600,
    height: 1200,
  },
  brochure:
    'https://assets.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltb748a93277ad1be9/mu-x-brochure-2025.pdf',
  //NOTE: permanent link
  // FOR SHOW A BADGE
  new: true,
};

export const X_SERIES_MENU: IMenu = {
  label: X_SERIES_DATA.label,
  description: `เริ่มต้น THB ${X_SERIES_DATA.price.toLocaleString()}`,
  url: X_SERIES_DATA.url,
  image: X_SERIES_DATA.image,
  brochure: X_SERIES_DATA.brochure,
};

export const VCROSS_MENU: IMenu = {
  label: V_CROSS_DATA.label,
  description: `เริ่มต้น THB ${V_CROSS_DATA.price.toLocaleString()}`,
  url: V_CROSS_DATA.url,
  image: V_CROSS_DATA.image,
  brochure: V_CROSS_DATA.brochure,
};

export const PICKUP_4DOOR_MENU: IMenu = {
  label: PICKUP_4DOOR_DATA.label,
  description: `เริ่มต้น THB ${PICKUP_4DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_4DOOR_DATA.url,
  image: PICKUP_4DOOR_DATA.image,
  brochure: PICKUP_4DOOR_DATA.brochure,
};

export const PICKUP_2DOOR_MENU: IMenu = {
  label: PICKUP_2DOOR_DATA.label,
  description: `เริ่มต้น THB ${PICKUP_2DOOR_DATA.price.toLocaleString()}`,
  url: PICKUP_2DOOR_DATA.url,
  image: PICKUP_2DOOR_DATA.image,
  brochure: PICKUP_2DOOR_DATA.brochure,
};

export const SPARK_MENU: IMenu = {
  label: SPARK_DATA.label,
  description: `เริ่มต้น THB ${SPARK_DATA.price.toLocaleString()}`,
  url: SPARK_DATA.url,
  image: SPARK_DATA.image,
  brochure: SPARK_DATA.brochure,
};

export const MUX_ULTIMATE_DATA_MENU: IMenu = {
  label: MUX_ULTIMATE_DATA.label,
  description: `เริ่มต้น THB ${MUX_ULTIMATE_DATA.price.toLocaleString()}`,
  url: MUX_ULTIMATE_DATA.url,
  image: MUX_ULTIMATE_DATA.image,
  brochure: MUX_ULTIMATE_DATA.brochure,
};

export const MUX_RS_DATA_MENU: IMenu = {
  label: MUX_RS_DATA.label,
  description: `เริ่มต้น THB ${MUX_RS_DATA.price.toLocaleString()}`,
  url: MUX_RS_DATA.url,
  image: MUX_RS_DATA.image,
  brochure: MUX_RS_DATA.brochure,
  // FOR SHOW A BADGE
  new: MUX_RS_DATA.new,
};

export const getModelLineupId = (model: IMy24Model) => {
  const MODEL_LINE_UP_ID = {
    'mu-x': 1,
    'v-cross': 2,
    '4-door': 3,
    '2-door': 4,
    spark: 5,
    'x-series': 6,
  };
  return MODEL_LINE_UP_ID[model] || 1;
};

export const HOME_LINEUP_DATA: IMy24LineupItem[] = [
  {
    preTitle: 'MU-X',
    title: 'มิว-เอ็กซ์',
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95d5d1008250eead/66692ca146a0375d52a26414/mu-x-front-rs-eiger-grey.png',
      alt: MUX_ULTIMATE_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd2255779dd00e168/665996b75311e3c53bce84a8/mu-x-lineup-bg.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc36c2913b3c62ba7/665996b744be248c94e20689/mu-x-lineup-bg-desktop.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: '/isuzu-mu-x',
      color: 'button-gray',
      isMinWidth: true,
    },
    price: MUX_ULTIMATE_DATA.price,
  },
  {
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltab16cade4bd5daee/651f7880f156d5928caf21da/3-0-ddi-m-4-door-at-namibu-orange-mica-front.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blte456dec813c4503a/651668c0098aaa9f88ce49be/home-lineup-bg-v-cross-mobile.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt391cae8117816279/651668f5474d4d73914f465d/home-lineup-bg-v-cross.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta194a0bce6272f50/651f73e62b65932b83c58368/hi-lander-4-door-3-0-ddi-m-dolomite-pearl-white.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9f99dc2ea4f1adc2/65166a2623b7e78f5e6aa95c/home-lineup-bg-4-door-mobile.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blteee13e08dc626ff6/651669f1ec958dbcd8ea56b4/home-lineup-bg-4-door.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    preTitle: '2-DOOR',
    title: PICKUP_2DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt56540ac740e15270/651f6b7b7bedef8ec594d891/spacecab-1-9-ddi-l-at-islay-gray-opaque-front.png',
      alt: PICKUP_2DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltfdb10b89c9309abf/651a372d375d9858b19cd417/home-lineup-bg-2-door-mobile.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1742a8452ba21e70/651a3749f156d54cbbaf05da/home-lineup-bg-2-door.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_2DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_2DOOR_DATA.price,
  },
  {
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1198d797f268acbe/651f6c1f705ef3222048b515/spark-4x4-3-0-ddi-s-mt-bohemian-silver-metallic-front.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc51540266564afd1/651669a623b7e7b9f46aa95a/home-lineup-bg-spark-mobile.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt180e8b8b245e62df/651669cccf50bf3f2afffa5c/home-lineup-bg-spark.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    preTitle: 'X-SERIES',
    title: X_SERIES_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta320386b316dfe35/65a7f250c992b705a5b89fc7/x-series-front-speed-4-door-mt-bavarian-black-mica.png',
      alt: X_SERIES_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt93ed6b5cae0741f3/65a8e4281e1aed5c6bfd43d7/x-series-bg.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta4d162e54c237555/65a8e3f9d7d856eaecb4eb5b/x-series-bg-desktop.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: X_SERIES_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: X_SERIES_DATA.price,
    theme: 'dark',
  },
];

export const PRODUCT_LINEUP_DATA: IMy24LineupItem[] = [
  {
    preTitle: 'MU-X',
    title: 'มิว-เอ็กซ์',
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt95d5d1008250eead/66692ca146a0375d52a26414/mu-x-front-rs-eiger-grey.png',
      alt: MUX_ULTIMATE_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd2255779dd00e168/665996b75311e3c53bce84a8/mu-x-lineup-bg.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc36c2913b3c62ba7/665996b744be248c94e20689/mu-x-lineup-bg-desktop.jpg',
      alt: MUX_ULTIMATE_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: '/isuzu-mu-x',
      color: 'button-gray',
      isMinWidth: true,
    },
    price: MUX_ULTIMATE_DATA.price,
  },
  {
    preTitle: 'V-CROSS',
    title: V_CROSS_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltab16cade4bd5daee/651f7880f156d5928caf21da/3-0-ddi-m-4-door-at-namibu-orange-mica-front.png',
      alt: V_CROSS_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6ef1a3411dc0d7f6/651665744f2fa8e5cb69a5ae/bg-v-cross-desktop.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt6ef1a3411dc0d7f6/651665744f2fa8e5cb69a5ae/bg-v-cross-desktop.jpg',
      alt: V_CROSS_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: V_CROSS_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: V_CROSS_DATA.price,
  },
  {
    preTitle: '4-DOOR',
    title: PICKUP_4DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta194a0bce6272f50/651f73e62b65932b83c58368/hi-lander-4-door-3-0-ddi-m-dolomite-pearl-white.png',
      alt: PICKUP_4DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt26af7ebfa7390de6/651664a74d72e9a72384cf5d/bg-4-door-desktop.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt26af7ebfa7390de6/651664a74d72e9a72384cf5d/bg-4-door-desktop.jpg',
      alt: PICKUP_4DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_4DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_4DOOR_DATA.price,
  },
  {
    preTitle: '2-DOOR',
    title: PICKUP_2DOOR_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt56540ac740e15270/651f6b7b7bedef8ec594d891/spacecab-1-9-ddi-l-at-islay-gray-opaque-front.png',
      alt: PICKUP_2DOOR_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt070c8494d1080e3c/651a3b3cf64f15c803b45911/bg-2-door-desktop.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt070c8494d1080e3c/651a3b3cf64f15c803b45911/bg-2-door-desktop.jpg',
      alt: PICKUP_2DOOR_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: PICKUP_2DOOR_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: PICKUP_2DOOR_DATA.price,
  },
  {
    preTitle: 'SPARK',
    title: SPARK_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1198d797f268acbe/651f6c1f705ef3222048b515/spark-4x4-3-0-ddi-s-mt-bohemian-silver-metallic-front.png',
      alt: SPARK_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt93f0049e0c1a5e85/6516654c8f04571e4212b636/bg-spark-desktop.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt93f0049e0c1a5e85/6516654c8f04571e4212b636/bg-spark-desktop.jpg',
      alt: SPARK_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: SPARK_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: SPARK_DATA.price,
  },
  {
    preTitle: 'X-SERIES',
    title: X_SERIES_DATA.label,
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta320386b316dfe35/65a7f250c992b705a5b89fc7/x-series-front-speed-4-door-mt-bavarian-black-mica.png',
      alt: X_SERIES_DATA.label,
      width: 600,
      height: 338,
    },
    bg: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2e85eaecb1d5dd93/65a8e3a9c1930ffcbff8ae39/x-series-bg.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    bgDesktop: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2e85eaecb1d5dd93/65a8e3a9c1930ffcbff8ae39/x-series-bg.jpg',
      alt: X_SERIES_DATA.label,
      width: 1680,
      height: 720,
    },
    button: {
      label: 'ดูรายละเอียด',
      icon: 'arrow-forward',
      url: X_SERIES_DATA.url,
      color: 'button-gray',
      isMinWidth: true,
    },
    price: X_SERIES_DATA.price,
  },
];
