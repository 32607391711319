import {
  MUX_RS_DATA_MENU as MUX_RS_DATA_MENU_2024,
  MUX_ULTIMATE_DATA_MENU as MUX_ULTIMATE_DATA_MENU_2024,
  PICKUP_2DOOR_MENU as PICKUP_2DOOR_MENU_2024,
  PICKUP_4DOOR_MENU as PICKUP_4DOOR_MENU_2024,
  SPARK_MENU as SPARK_MENU_2024,
  VCROSS_MENU as VCROSS_MENU_2024,
  X_SERIES_MENU as X_SERIES_MENU_2024,
} from '@/contents/lcv/product/bluepower/shared/lineup';
import {
  MUX_RS_DATA_MENU,
  MUX_ULTIMATE_DATA_MENU,
  PICKUP_2DOOR_MENU,
  PICKUP_4DOOR_MENU,
  SPARK_MENU,
  VCROSS_MENU,
  X_SERIES_MENU,
} from '@/contents/lcv/product/maxforce/shared/lineup';
import { IMenu } from '@/types/layout/menu';
import { IButton } from '@/types/shared/button';

export const MENU_LCV_SECONDARY_NAV_LCV: IMenu = {
  label: 'รถปิกอัพและรถอเนกประสงค์',
  url: '/',
  active: true,
};

export const MENU_LCV_SECONDARY_NAV_CV: IMenu = {
  label: 'รถบรรทุก',
  url: 'https://truck.isuzu-tis.com',
  target: '_blank',
};

export const MENU_LCV_SECONDARY_NAV: IMenu[] = [
  MENU_LCV_SECONDARY_NAV_LCV,
  MENU_LCV_SECONDARY_NAV_CV,
];

// LCV BUYING TOOLS
export const MENU_LCV_REGISTER: IMenu = {
  label: 'สนใจรถ / <span>ทดลองขับ</span>',
  description: `ลงทะเบียนสนใจรถอีซูซุ<span>หรือทดลองขับ</span>`,
  url: '/register',
  icon: 'steering-wheel',
  shortLabel: 'ทดลองขับ',
};

export const MENU_LCV_COMPARE: IMenu = {
  label: 'เปรียบเทียบ<span>รุ่นรถ</span>',
  description: `เปรียบเทียบข้อมูลรถทุกรุ่น<span>โดยละเอียด</span>`,
  url: '/compare',
  icon: 'compare',
  shortLabel: 'เปรียบเทียบ',
};

export const MENU_LCV_CALCULATOR: IMenu = {
  label: 'คำนวณ<span>ค่างวด</span>',
  description: `คำนวณค่าใช้จ่าย<span>ในการออกรถ</span>`,
  url: '/calculator',
  icon: 'calculator',
  shortLabel: 'คำนวณ',
};

export const MENU_LCV_DEALER: IMenu = {
  label: 'ค้นหาโชว์รูม / <span>ศูนย์บริการ</span>',
  description: `ค้นหาโชว์รูมอีซูซุ<span>ใกล้บ้านคุณ</span><span>ได้ที่นี่</span>`,
  url: '/dealer',
  icon: 'place',
  shortLabel: 'โชว์รูม',
};

export const MENU_LCV_DOWNLOAD_BROCHURE: IMenu = {
  label: 'ดาวน์โหลด<span>โบรชัวร์</span>',
  description: `ดาวน์โหลดโบรชัวร์<span>ของรถอีซูซุ</span><span>ทุกรุ่น</span>`,
  url: '/download-brochure',
  icon: 'map',
  shortLabel: 'โบรชัวร์',
};

export const MENU_LCV_BUYING_TOOLS: IMenu[] = [
  MENU_LCV_REGISTER,
  MENU_LCV_COMPARE,
  MENU_LCV_CALCULATOR,
  MENU_LCV_DEALER,
  MENU_LCV_DOWNLOAD_BROCHURE,
];

export const MENU_LCV_MODEL_LABEL = 'รุ่นรถอีซูซุ';
export const MENU_LCV_MODEL_PICKUP_LABEL = 'รถปิกอัพ';
export const MENU_LCV_MODEL_SUV_LABEL = 'รถอเนกประสงค์';
export const MENU_LCV_MODEL_D_MAX_LABEL = 'อีซูซุดีแมคซ์';
export const MENU_LCV_MODEL_MU_X_LABEL = 'มิว-เอ็กซ์';
export const MENU_LCV_MODEL_MU_X_RS_LABEL = 'มิว-เอ็กซ์ อาร์เอส';

export const MENU_LCV_MODEL_HEADER: IMenu = {
  label: MENU_LCV_MODEL_LABEL,
  url: '',
  layout: 2,
  noBreaklineSubmenu: true,
  submenu: [
    {
      url: '',
      label: MENU_LCV_MODEL_PICKUP_LABEL,
      submenu: [
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [VCROSS_MENU],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [PICKUP_4DOOR_MENU],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [PICKUP_2DOOR_MENU],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [SPARK_MENU],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_D_MAX_LABEL,
          submenu: [X_SERIES_MENU],
        },
      ],
    },
    {
      url: '',
      label: MENU_LCV_MODEL_SUV_LABEL,
      isFlexColumn: true,
      submenu: [
        {
          url: '',
          label: MENU_LCV_MODEL_MU_X_RS_LABEL,
          submenu: [MUX_RS_DATA_MENU],
        },
        {
          url: '',
          label: MENU_LCV_MODEL_MU_X_LABEL,
          submenu: [MUX_ULTIMATE_DATA_MENU],
        },
      ],
    },
  ],
};
const MENU_LCV_MODEL_FOOTER_SUBMENU: IMenu[] = [
  {
    url: '',
    label: MENU_LCV_MODEL_PICKUP_LABEL,
    submenu: [
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [VCROSS_MENU],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [PICKUP_4DOOR_MENU],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [PICKUP_2DOOR_MENU],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [SPARK_MENU],
      },
    ],
  },
  {
    url: '',
    label: MENU_LCV_MODEL_SUV_LABEL,
    isFlexColumn: true,
    submenu: [
      {
        url: '',
        label: MENU_LCV_MODEL_MU_X_RS_LABEL,
        submenu: [MUX_RS_DATA_MENU],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_MU_X_LABEL,
        submenu: [MUX_ULTIMATE_DATA_MENU],
      },
    ],
  },
];

const MENU_LCV_MODEL_FOOTER_SUBMENU_2024: IMenu[] = [
  {
    url: '',
    label: MENU_LCV_MODEL_PICKUP_LABEL,
    submenu: [
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [VCROSS_MENU_2024],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [PICKUP_4DOOR_MENU_2024],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [PICKUP_2DOOR_MENU_2024],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [SPARK_MENU_2024],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_D_MAX_LABEL,
        submenu: [X_SERIES_MENU_2024],
      },
    ],
  },
  {
    url: '',
    label: MENU_LCV_MODEL_SUV_LABEL,
    isFlexColumn: true,
    submenu: [
      {
        url: '',
        label: MENU_LCV_MODEL_MU_X_RS_LABEL,
        submenu: [MUX_RS_DATA_MENU_2024],
      },
      {
        url: '',
        label: MENU_LCV_MODEL_MU_X_LABEL,
        submenu: [MUX_ULTIMATE_DATA_MENU_2024],
      },
    ],
  },
];
export const MENU_LCV_MODEL_FOOTER: IMenu = {
  label: 'รุ่นเครื่องยนต์ MAXFORCE',
  url: '',
  layout: 2,
  noBreaklineSubmenu: true,
  submenu: MENU_LCV_MODEL_FOOTER_SUBMENU,
};
export const MENU_LCV_MODEL_FOOTER_2024: IMenu = {
  label: 'รุ่นเครื่องยนต์ <span>BLUE POWER</span>',
  url: '',
  layout: 2,
  noBreaklineSubmenu: true,
  toggleShowSubmenu: true,
  defaultStateToggleShowSubmenu: false,
  submenu: MENU_LCV_MODEL_FOOTER_SUBMENU_2024,
};
export const MENU_LCV_MODEL_CV_FOOTER: IMenu = {
  label: 'รถบรรทุกอีซูซุ',
  url: 'https://truck.isuzu-tis.com',
  target: '_blank',
};

// LCV SPECIAL OFFER
export const MENU_LCV_SPECIAL_OFFER: IMenu = {
  label: 'ข้อเสนอพิเศษ',
  url: '/specialoffer',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt5d8c135f55365523/6743fc9ce601918f73c3d930/lcv-special-offer.jpg',
    alt: 'offers',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SPECIAL_OFFER_FINANCE: IMenu = {
  label: 'สินเชื่อและไฟแนนซ์',
  // url: 'https://www.isuzu-tis.com/finance',
  // url: '/specialoffer/finance',
  url: '/finance',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt879b5711456a2db4/63b69ad7be2db12f87056afe/TIL_MENU.png',
    alt: 'Special offer finance',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SPECIAL_OFFER_SERVICE: IMenu = {
  label: 'บริการหลังกายขาย',
  url: '/specialoffer/service-campaign',
};

export const MENU_LCV_SPECIAL_OFFER_PUP: IMenu = {
  label: 'รถปิกอัพ',
  url: '/specialoffer/pup',
};

export const MENU_LCV_SPECIAL_OFFER_PPV: IMenu = {
  label: 'รถอเนกประสงค์',
  url: '/specialoffer/ppv',
};

export const MENU_LCV_MASTEROFALLROADS: IMenu = {
  label: 'MASTER OF ALL ROADS',
  url: '/masterofallroads',
};

export const MENU_LCV_SPECIAL_OFFER_AND_FINANCE: IMenu = {
  label: 'ข้อเสนอและไฟแนนซ์',
  url: '',
  submenu: [MENU_LCV_SPECIAL_OFFER, MENU_LCV_SPECIAL_OFFER_FINANCE],
};

// LCV SERVICE
export const MENU_LCV_SERVICE_CENTER: IMenu = {
  label: 'ศูนย์บริการ',
  url: '/service/service-center',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt1da8ddb16230b38b/65e953e0c9226cfe49e85e3f/showroom.jpg',
    alt: 'service center',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SERVICE_MAINTENANCE_LIST: IMenu = {
  label: 'รายการบำรุงรักษาตามระยะ',
  url: '/service/maintenance-list',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd4ccb6c593303459/627886a2220ab5285f51c192/maintenance.jpg',
    alt: 'maintenance list',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SERVICE_PARTS: IMenu = {
  label: 'อะไหล่สำหรับรถอีซูซุ',
  url: '/service/parts',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7110f5853f6fa8e9/627886a2deee7329767ea589/parts.jpg',
    alt: 'isuzu parts',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SERVICE_WARRANTY: IMenu = {
  label: 'การรับประกัน',
  url: '/service/warranty',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltf415e48b68b3cba4/61f211475b57f325c91372b4/5-LCVservice.jpg',
    alt: 'isuzu warranty',
    width: 1600,
    height: 1200,
  },
  // showpanel: true,
};

export const MENU_LCV_SERVICE_CV: IMenu = {
  label: 'บริการรถบรรทุก',
  url: 'https://truck.isuzu-tis.com/lcs/service-center',
  target: '_blank',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd649870ef9a38bbe/627886a223c6cf2a1c3b2343/truck-service.jpg',
    alt: 'service center truck',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SERVICE_ONWER_MANUAL: IMenu = {
  label: 'คู่มือการใช้รถอีซูซุ',
  url: '/service/owners-manual',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt2001320d5a1838c5/6744a4c3de8d393759ef67f9/menu-manual.jpg',
    alt: 'owner menual',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_SERVICE: IMenu = {
  label: 'บริการ',
  url: '',
  submenu: [
    MENU_LCV_SERVICE_CENTER,
    MENU_LCV_SERVICE_MAINTENANCE_LIST,
    MENU_LCV_SERVICE_PARTS,
    // MENU_LCV_SERVICE_WARRANTY,
    MENU_LCV_SERVICE_ONWER_MANUAL,
    MENU_LCV_SERVICE_CV,
  ],
};

// LCV PRIVILEGES
export const MENU_LCV_PRIVILEGES_MY_ISUZU: IMenu = {
  label: 'my-ISUZU',
  url: '/privileges/my-isuzu',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt24a89ac1f2e58308/655c21c55af53925e059cc2f/my-isuzu.jpg',
    alt: 'my-ISUZU',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_PRIVILEGES_EXCELLENCY_CLUB: IMenu = {
  label: 'สมาชิก “อีซูซุ ยิ่งเข้า ยิ่งคุ้ม”',
  url: '/privileges/ying-kao-ying-koom',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt64b74c648ccf2d97/65e87882c4428d8c8ea1dec9/lcv-ying-kao-ying-koom.jpg',
    alt: 'isuzu ying kao ying koom',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_PRIVILEGES_MUX_THE_EXCLUSIVE: IMenu = {
  label: 'อีซูซุมิว-เอ็กซ์ ดิ เอ็กซ์คลูซีฟ',
  url: '/privileges/mu-x-the-exclusive',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltc36d732e78662484/65e878eb7d810afa3741a0fb/mu-x-the-exclusive.jpg',
    alt: 'mu-x the exclusive',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_PRIVILEGES_SMART_PROTECTION: IMenu = {
  label: 'การขยายการรับประกันรถอีซูซุ',
  url: '/privileges/smart-protection',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltce0d2163aaaecc6b/627887750d4d8e1d7f111c1f/isp.jpg',
    alt: 'isuzu smart protection',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_PRIVILEGES_VALUATION: IMenu = {
  label: 'ประเมินรถอีซูซุของฉัน',
  url: '/privileges/estimate',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltffccab33b157292a/62788774deee7329767ea58d/evaluate.jpg',
    alt: 'estimate',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_PRIVILEGES: IMenu = {
  label: 'สิทธิประโยชน์',
  url: '',
  submenu: [
    MENU_LCV_PRIVILEGES_MY_ISUZU,
    MENU_LCV_PRIVILEGES_EXCELLENCY_CLUB,
    MENU_LCV_PRIVILEGES_MUX_THE_EXCLUSIVE,
    MENU_LCV_PRIVILEGES_SMART_PROTECTION,
    // MENU_LCV_PRIVILEGES_VALUATION,
  ],
};

// LCV OTHER SERVICE
export const MENU_LCV_OTHER_SERVICE_LEASING: IMenu = {
  label: 'อีซูซุลิสซิ่ง',
  url: 'https://www.isuzuleasing.com',
  target: '_blank',
  showpanel: true,
};

export const MENU_LCV_OTHER_SERVICE_INSURANCE: IMenu = {
  label: 'ตรีเพชรอินชัวร์รันส์เซอร์วิส',
  url: 'https://www.tripetchinsurance.com',
  target: '_blank',
  showpanel: true,
};

export const MENU_LCV_OTHER_SERVICE_TELETEC: IMenu = {
  label: 'เทเลเทค',
  url: 'https://truck.isuzu-tis.com/lcs/teletec',
  target: '_blank',
  showpanel: true,
};

export const MENU_LCV_OTHER_SERVICE_TRUCK2HAND: IMenu = {
  label: 'Truck2Hand',
  url: 'https://www.truck2hand.com',
  target: '_blank',
  showpanel: true,
};

const MENU_LCV_OTHER_SERVICE_GROUP: IMenu[] = [
  MENU_LCV_OTHER_SERVICE_LEASING,
  MENU_LCV_OTHER_SERVICE_INSURANCE,
  MENU_LCV_OTHER_SERVICE_TELETEC,
  MENU_LCV_OTHER_SERVICE_TRUCK2HAND,
];

export const MENU_LCV_OTHER_SERVICE: IMenu = {
  label: 'บริการอื่นๆ',
  url: '',
  submenu: MENU_LCV_OTHER_SERVICE_GROUP,
  showpanel: true,
};

// LCV CONTENT HUB
export const MENU_LCV_CONTENT_HUB = {
  url: '/content-hub',
};

export const MENU_LCV_CONTENT_HUB_EXPERIENCES: IMenu = {
  label: 'เรื่องราวจากผู้ใช้อีซูซุ',
  url: '/content-hub/experiences',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltbec5e23abc990963/630c464c44cff61b783b0410/testimonials.jpg',
    alt: 'เรื่องราวจากผู้ใช้อีซูซุ',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_CONTENT_HUB_EXPERT: IMenu = {
  label: 'เรื่องน่ารู้จากอีซูซุ',
  url: '/content-hub/expert',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt540f4ae57259c951/6278885d0d4d8e1d7f111c23/tips.jpg',
    alt: 'เรื่องน่ารู้จากอีซูซุ',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_CONTENT_HUB_GALLERY: IMenu = {
  label: 'แกลเลอรี่',
  url: '/content-hub/inspiration',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt7d74d7437e2fdcd2/6743fd28256086ae54df381b/menu-share.jpg',
    alt: 'แกลเลอรี่',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_CONTENT_HUB_GROUP: IMenu = {
  label: 'แชร์สิ่งดีๆ',
  url: '',
  submenu: [
    MENU_LCV_CONTENT_HUB_EXPERIENCES,
    MENU_LCV_CONTENT_HUB_EXPERT,
    MENU_LCV_CONTENT_HUB_GALLERY,
  ],
};

// LCV ABOUT
export const MENU_LCV_ABOUT_HISTORY: IMenu = {
  label: 'ประวัติอีซูซุ',
  url: '/about-history',
  showpanel: true,
};

export const MENU_LCV_ABOUT_NEWS: IMenu = {
  label: 'ข่าวสารและกิจกรรม',
  url: '/news-activities',
  showpanel: true,
};

export const MENU_LCV_ABOUT_CONTACT: IMenu = {
  label: 'ติดต่อตรีเพชรอีซูซุ',
  url: '/contact-us',
  showpanel: true,
};

export const MENU_LCV_ABOUT_CAREER: IMenu = {
  label: 'สมัครงาน',
  url: 'https://career.tripetchgroup.com',
  target: '_blank',
  showpanel: true,
};

export const MENU_LCV_ABOUT_PRIVACY_POLICY: IMenu = {
  label: 'นโยบายความเป็นส่วนตัว',
  url: '/privacy-policy',
  showpanel: true,
};

export const MENU_LCV_ABOUT_FAQ: IMenu = {
  label: 'คำถามที่พบบ่อย',
  url: '/faqs',
  showpanel: true,
};

export const MENU_LCV_ABOUT_SUBMENU: IMenu[] = [
  MENU_LCV_ABOUT_FAQ,
  MENU_LCV_ABOUT_HISTORY,
  MENU_LCV_ABOUT_NEWS,
  MENU_LCV_ABOUT_CONTACT,
  MENU_LCV_ABOUT_CAREER,
  MENU_LCV_ABOUT_PRIVACY_POLICY,
];

export const MENU_LCV_ABOUT: IMenu = {
  label: 'เกี่ยวกับอีซูซุ',
  url: '',
  submenu: MENU_LCV_ABOUT_SUBMENU,
  showpanel: true,
};

// LCV SOCIAL
export const MENU_LCV_SOCIAL_FACEBOOK_DMAX: IMenu = {
  label: 'ISUZU D-MAX Fanpage',
  url: 'https://www.facebook.com/ALLNEWISUZUDMAX',
  target: '_blank',
  icon: 'facebook-circle',
};

export const MENU_LCV_SOCIAL_FACEBOOK_MUX: IMenu = {
  label: 'ISUZU MU-X Fanpage',
  url: 'https://www.facebook.com/isuzumuxthailand',
  target: '_blank',
  icon: 'facebook-circle',
};

export const MENU_LCV_SOCIAL_LINE: IMenu = {
  label: 'Line Official',
  url: 'https://line.me/R/ti/p/%40isuzuthai',
  target: '_blank',
  icon: 'line-circle',
};

export const MENU_LCV_SOCIAL_YOUTUBE: IMenu = {
  label: 'Youtube Channel',
  url: 'https://www.youtube.com/channel/UCmTP28q4oZsMCyx16PiPJwA',
  target: '_blank',
  icon: 'youtube-circle',
};

export const MENU_LCV_SOCIAL_INSTAGRAM: IMenu = {
  label: 'Instagram Channel',
  url: 'https://www.instagram.com/isuzuthailandofficial',
  target: '_blank',
  icon: 'instagram-circle',
};

export const MENU_LCV_SOCIAL_TIKTOK: IMenu = {
  label: 'Tiktok Channel',
  url: 'https://www.tiktok.com/@isuzuthailandofficial',
  target: '_blank',
  icon: 'tiktok-circle',
};

const MENU_LCV_SOCIAL_GROUP: IMenu[] = [
  MENU_LCV_SOCIAL_FACEBOOK_DMAX,
  MENU_LCV_SOCIAL_FACEBOOK_MUX,
  MENU_LCV_SOCIAL_LINE,
  MENU_LCV_SOCIAL_YOUTUBE,
  MENU_LCV_SOCIAL_INSTAGRAM,
  MENU_LCV_SOCIAL_TIKTOK,
];

export const MENU_LCV_SOCIAL: IMenu = {
  label: 'ช่องทางติดต่อออนไลน์',
  url: '',
  submenu: MENU_LCV_SOCIAL_GROUP,
  showpanel: true,
};

// LCV SOCIAL CONTACTS
export const MENU_LCV_TRI_PETCH_GROUP: IMenu = {
  label: '',
  url: 'https://www.tripetchgroup.com',
  target: '_blank',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blta0ce0ab3e39a763f/61dd8fa825d68c388a00fc2c/logo-tripetchgroup.svg',
    alt: 'ติดต่อกลุ่มตรีเพชร',
    width: 207,
    height: 62,
  },
};

export const MENU_LCV_ISUZU_HOTLINE: IMenu = {
  label: '',
  url: 'tel:021180777',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt33f0eff0eb661808/621dd2525662980e56b3b7c3/logo-isuzu-hotline.svg',
    alt: 'ISUZU HOTLINE สายด่วน',
    width: 200,
    height: 76,
  },
};

export const MENU_LCV_CONTACT: IMenu = {
  label: 'ช่องทางการติดต่อ',
  url: '',
  submenu: [MENU_LCV_TRI_PETCH_GROUP, MENU_LCV_ISUZU_HOTLINE],
};

export const BUTTON_LCV_FIND_SERVICE_CENTER: IButton = {
  label: 'ค้นหาศูนย์บริการ',
  url: MENU_LCV_DEALER.url + '?service=1&vehicle=lcv',
  target: MENU_LCV_DEALER.target ?? undefined,
  icon: MENU_LCV_DEALER.icon,
};

export const BUTTON_LCV_FIND_SERVICE_CENTER_BORDER: IButton = {
  label: 'ค้นหาศูนย์บริการ',
  url: MENU_LCV_DEALER.url + '?service=1&vehicle=lcv',
  target: MENU_LCV_DEALER.target ?? undefined,
  icon: MENU_LCV_DEALER.icon,
  type: 'border',
};

// LCV MASTER OF ALL ROAD
export const MENU_LCV_MASTER_OF_ALL_ROAD = {
  label: 'ภาพรวม',
  url: '/masterofallroads',
};

export const MENU_LCV_MASTER_OF_ALL_ROAD_GALLERY: IMenu = {
  label: 'แกลเลอรี่',
  url: '/masterofallroads/gallery',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt762acd2fb4b8f32b/63341af6ecd0b92b89dfedc0/menu_gallery_3.jpg',
    alt: 'แกลเลอรี่',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_MASTER_OF_ALL_ROAD_VIDEO: IMenu = {
  label: 'วิดีโอ',
  url: '/masterofallroads/video',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt762acd2fb4b8f32b/63341af6ecd0b92b89dfedc0/menu_gallery_3.jpg',
    alt: 'วิดีโอ',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_MASTER_OF_ALL_ROAD_ACTIVITY: IMenu = {
  label: 'กิจกรรม',
  url: '/masterofallroads/activity',
  image: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt762acd2fb4b8f32b/63341af6ecd0b92b89dfedc0/menu_gallery_3.jpg',
    alt: 'กิจกรรม',
    width: 1600,
    height: 1200,
  },
};

export const MENU_LCV_MASTER_OF_ALL_ROAD_GROUP: IMenu = {
  label: 'MASTER OF ALL ROAD',
  url: '',
  submenu: [
    MENU_LCV_MASTER_OF_ALL_ROAD,
    MENU_LCV_MASTER_OF_ALL_ROAD_VIDEO,
    MENU_LCV_MASTER_OF_ALL_ROAD_GALLERY,
    MENU_LCV_MASTER_OF_ALL_ROAD_ACTIVITY,
  ],
};

export const MENU_LCV_PRIMARY_NAV: IMenu[] = [
  MENU_LCV_MODEL_HEADER,
  MENU_LCV_SPECIAL_OFFER_AND_FINANCE,
  MENU_LCV_SERVICE,
  MENU_LCV_PRIVILEGES,
  MENU_LCV_CONTENT_HUB_GROUP,
  // ...MENU_LCV_OTHER_SERVICE_GROUP,
  // ...MENU_LCV_ABOUT_SUBMENU,
  MENU_LCV_OTHER_SERVICE,
  MENU_LCV_ABOUT,
  // MENU_LCV_SOCIAL,
];

export const COPYRIGHT = `สงวนลิขสิทธิ์ &copy; 2024 บริษัท ตรีเพชรอีซูซุเซลส์ จำกัด`;
