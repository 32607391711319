import { type ProductEngineSwitchItem } from '@/components/sections/einstein/product/engine';
import { type EinsteinEngineType } from '@/types/shared/einstein/engine';

export const HP_VALUE = {
  '1.9': '150',
  '2.2': '163',
  '3.0': '190',
};
export const TORQUE_VALUE = {
  '1.9': '350',
  '2.2': '400',
  '3.0': '450',
};

export const HP_ITEM = {
  title: 'กำลังสูงสุด',
  'value_2.2': HP_VALUE['2.2'],
  'value_3.0': HP_VALUE['3.0'],
  unit: 'แรงม้า',
};
export const TORQUE_ITEM = {
  title: 'แรงบิดสูงสุด',
  'value_2.2': TORQUE_VALUE['2.2'],
  'value_3.0': TORQUE_VALUE['3.0'],
  unit: 'นิวตัน-เมตร',
};
export const EFFICIENCY_ITEM = {
  title: 'อัตราสิ้นเปลืองดีขึ้น',
  'value_2.2': '10',
  unit: 'เปอร์เซ็น',
};

export const ENGINE_SWITCH_OPTIONS: {
  value: EinsteinEngineType;
  label: string;
}[] = [
  {
    value: '2.2',
    label: 'เครื่องยนต์ 2.2',
  },
  {
    value: '3.0',
    label: 'เครื่องยนต์ 3.0',
  },
];

export const SHARED_PRODUCT_ENGINE_DATA = {
  active: true,
  menu: {
    hash: '#engine',
    label: 'เครื่องยนต์',
  },
  logo: {
    src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt9418f6ca0bcd59cd/671f381b38ade961c8d19388/ENGINE.svg',
    alt: 'MAXFORCE',
    width: 854,
    height: 240,
  },
  cta: {
    link: '/engine',
    label: 'ดูรายละเอียดเครื่องยนต์',
    test: {
      dataTest: 'product_cta_engine',
    },
    tracking: {
      dataTrack: 'lcv-product',
      dataTrackSection: 'product_cta_engine',
      dataTrackText: 'ดูรายละเอียดเครื่องยนต์',
      dataTrackUrl: '/engine',
    },
  },
};

export const ENGINE_PRODUCT_ITEMS: ProductEngineSwitchItem[] = [
  {
    value: '2.2',
    title: '2.2 Ddi MAXFORCE',
    description:
      'เครื่องยนต์ดีเซลแห่งอนาคต ความจุ <span>2.2 ลิตร</span> เหมาะสมยิ่งขึ้นด้วยเทคโนโลยีดีเซลสำหรับอนาคตข้างหน้า ให้กำลังแรงขึ้น ออกตัวเร็ว<span>ยิ่งขึ้น</span> <span>แบบฉบับอีซูซุ</span>',
    hp: {
      label: HP_ITEM.title,
      unit: HP_ITEM.unit,
      value: HP_ITEM['value_2.2'],
      color: '#C465F4',
    },
    torque: {
      label: TORQUE_ITEM.title,
      unit: TORQUE_ITEM.unit,
      value: TORQUE_ITEM['value_2.2'],
      color: '#C465F4',
    },
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/blt8cdb08a13a5ff009/673af9af9eb4c3314984696e/engine2-2.png',
      alt: 'NEW! 2.2 Ddi MAXFORCE',
      width: 312,
      height: 312,
    },
  },
  {
    value: '3.0',
    title: '3.0 Ddi MAXFORCE',
    description:
      'ความแรงแบบฉบับอนาคต กับเครื่องยนต์ดีเซลเทคโนโลยีใหม่ล่าสุด ISUZU <span>3.0 Ddi MAXFORCE</span> ตอบโจทย์อรรถรส<span>การขับขี่</span> ด้วยสมรรถนะดีที่สุด<span>รอบด้าน</span>',
    hp: {
      label: HP_ITEM.title,
      unit: HP_ITEM.unit,
      value: HP_ITEM['value_3.0'],
      color: '#54A0CF',
    },
    torque: {
      label: TORQUE_ITEM.title,
      unit: TORQUE_ITEM.unit,
      value: TORQUE_ITEM['value_3.0'],
      color: '#54A0CF',
    },
    image: {
      src: 'https://images.contentstack.io/v3/assets/blt04fb7dc282801dd0/bltd578917047b677f2/673af9af0f505a32d4532bd1/engine3-0.png',
      alt: 'NEW! 3.0 Ddi MAXFORCE',
      width: 312,
      height: 312,
    },
  },
];
